export const generateSearchEngineCard = (searchString: string) => {
  if (!searchString.trim()) return [];
  const result = [
    {
      name: "使用百度搜索",
      url: searchBaidu(searchString),
      desc: `在百度中搜索 「${searchString}」`,
      id: 8800880001,
      logo: "baidu.png",
      hide: false
    },
    {
      name: "使用 Bing 搜索",
      url: searchBing(searchString),
      desc: `在 Bing 中搜索 「${searchString}」`,
      id: 8800880002,
      logo: "bing.png",
      hide: false
    },
    {
      name: "使用 Google 搜索",
      url: searchGoogle(searchString),
      desc: `在 Google 中搜索 「${searchString}」`,
      id: 8800880003,
      logo: "google.png",
      hide: false
    },
    {
      name: "使用 Bilibili 搜索",
      url: searchbilibili(searchString),
      desc: `在 Bilibili 中搜索 「${searchString}」`,
      id: 880088004,
      logo: "bilibili.png",
      hide: false
    },
    {
      name: "使用 Acfun 搜索",
      url: searchacfun(searchString),
      desc: `在 Acfun 中搜索 「${searchString}」`,
      id: 8800880005,
      logo: "acfun.png",
      hide: false
    },
    {
      name: "使用 Z-Library 搜索",
      url: searchzlibrary(searchString),
      desc: `在 Z-Library 中搜索 「${searchString}」`,
      id: 8800880006,
      logo: "z-lib.is.png",
      hide: false
    },
    {
      name: "使用 Youtube 搜索",
      url: searchyoutube(searchString),
      desc: `在 Youtube 中搜索 「${searchString}」`,
      id: 8800880007,
      logo: "youtube.png",
      hide: false
    },
    {
      name: "使用 DuckDuckGo 搜索",
      url: searchduckduckgo(searchString),
      desc: `在 DuckDuckGo 中搜索 「${searchString}」`,
      id: 8800880008,
      logo: "duckduckgo.png",
      hide: false
    },
    {
      name: "使用 Sogou 搜索",
      url: searchsogou(searchString),
      desc: `在 Sogou 中搜索 「${searchString}」`,
      id: 8800880009,
      logo: "sogou.png",
      hide: false
    },
    {
      name: "微信公众号文章搜索",
      url: searchweixin(searchString),
      desc: `在搜狗微信中搜索 「${searchString}」`,
      id: 8800880010,
      logo: "sogou.png",
      hide: false
    }
  ]
  return result;
}



const searchBaidu = (q: string) => {
  return `https://www.baidu.com/s?wd=${q}`
}

const searchGoogle = (q: string) => {
  return `https://www.google.com/search?q=${q}`
}

const searchBing = (q: string) => {
  return `https://cn.bing.com/search?q=${q}`
}

const searchbilibili = (q: string) => {
  return `https://search.bilibili.com/all?keyword=${q}`
}

const searchacfun = (q: string) => {
  return `https://www.acfun.cn/search?keyword=${q}`
}

const searchzlibrary = (q: string) => {
  return `https://lib-gj5ykm6tx35vj5oxesw5cgkf.1lib.ph/s/${q}`
}

const searchyoutube = (q: string) => {
  return `https://www.youtube.com/results?search_query=${q}`
}

const searchduckduckgo = (q: string) => {
  return `https://duckduckgo.com/?q=${q}`
}

const searchsogou = (q: string) => {
  return `https://www.sogou.com/web?query=${q}`
}

const searchweixin = (q: string) => {
  return `https://weixin.sogou.com/weixin?type=2&s_from=input&query=${q}`
}